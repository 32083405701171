import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Transition } from 'semantic-ui-react';
import { Icon, Popup } from 'semantic';
import { GOOGLE_API_KEY } from 'utils/env';
import { systemTypes, getSystemCoordinates } from 'utils/systems';
import { Link } from 'react-router-dom';

const Marker = (props) => {
  const { system } = props;
  const systemType = systemTypes[system.type || 'other'];
  const color = '#247870';
  return (
    <Transition visible={props.visible} animation="browse" duration={2000}>
      <Popup
        on="click"
        pinned
        position="top center"
        content={
          <React.Fragment>
            <Link to={`/systems/${system.id}`}>{system.name}</Link>
          </React.Fragment>
        }
        trigger={
          <div
            style={{
              position: 'absolute',
              display: 'inline-block',
              width: '30px',
              top: '-38px',
              left: '-15px',
              justifyContent: 'center',
            }}>
            <Icon
              style={{
                position: 'absolute',
                marginTop: '7px',
                marginLeft: '8px',
                fill: 'white',
                cursor: 'pointer',
              }}
              name={systemType.icon}
            />
            <svg
              viewBox="0 0 100 125"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <path
                style={{
                  fill: color,
                  cursor: 'pointer',
                  filter: 'drop-shadow( 3px 5px 3px rgba(0, 0, 0, .9))',
                }}
                d="M96.7,50.5c0-25.8-21-46.9-46.9-46.9S3,24.6,3,50.5c0,21.3,14.1,39.2,33.4,44.9l9.8,23.5c1.6,2.9,5.6,2.9,7.2,0l9.8-23.5
	C82.6,89.6,96.7,71.7,96.7,50.5z"
              />
            </svg>
          </div>
        }
      />
    </Transition>
  );
};

function createOptions() {
  return {
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_TOP,
    },
    styles: [
      {
        stylers: [
          { saturation: -60 },
          { gamma: 0.8 },
          { lightness: 4 },
          { visibility: 'on' },
        ],
      },
    ],
  };
}

function getHeight() {
  return window.innerHeight - 160;
}

class Map extends React.Component {
  state = {
    visible: false,
    validItems: this.props.items.filter(
      (item) => getSystemCoordinates(item) !== null
    ),
  };
  static defaultProps = {
    label: 'Address',
    height: `${getHeight()}px`,
    zoom: 10,
  };

  componentDidMount() {
    const { validItems } = this.state;

    setTimeout(() => {
      if (validItems.length > 1) {
        const bounds = new window.google.maps.LatLngBounds();
        validItems.forEach((item) => {
          const coordinates = getSystemCoordinates(item);
          if (coordinates) {
            const { latitude, longitude } = coordinates;
            bounds.extend(new window.google.maps.LatLng(latitude, longitude));
          }
        });
        this.map.map_.fitBounds(bounds);
      }
      this.setState({ visible: true });
    }, 400);
  }

  render() {
    const { validItems } = this.state;
    const { height, zoom, items } = this.props;
    let currentZoom = zoom;
    const center = {
      lat: 44.967243,
      lng: -103.771556,
    };
    if (validItems && getSystemCoordinates(validItems[0])) {
      const { latitude, longitude } = getSystemCoordinates(validItems[0]);
      center.lat = latitude;
      center.lng = longitude;
    } else {
      if (!zoom) {
        currentZoom = 2;
      }
    }
    return (
      <div
        style={{
          height,
          width: '100%',
          marginBottom: '0.5em',
          marginTop: '0.5em',
        }}>
        <GoogleMapReact
          ref={(ref) => (this.map = ref)}
          options={createOptions}
          bootstrapURLKeys={{ key: GOOGLE_API_KEY, libraries: ['places'] }}
          defaultCenter={center}
          defaultZoom={currentZoom}>
          {validItems.map((item, i) => {
            const coordinates = getSystemCoordinates(item);
            if (!coordinates) {
              return null;
            }
            const { latitude, longitude } = coordinates;
            return (
              <Marker
                key={i}
                lat={latitude}
                lng={longitude}
                visible={this.state.visible}
                system={item}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
