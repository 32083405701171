export const systemCommandTypes = {
  control: {
    name: 'Control',
    icon: 'arrow-circle-up',
  },
};

export const systemControlActions = {
  getSystemInformation: {
    name: 'Get System Information',
    icon: 'info-circle',
  },
  downloadLogs: {
    name: 'Download Logs',
    icon: 'file-download',
  },
};
