import React from 'react';
import { Header, Table, Loader, Label } from 'semantic';
import { screen } from 'helpers';
import { formatDateTime } from 'utils/date';
import { formatRoles } from 'utils/permissions';

import Menu from './Menu';

@screen
export default class UserOverview extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {!user ? (
          <Loader active>Loading</Loader>
        ) : (
          <React.Fragment>
            <Header as="h3">Details</Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Email</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Roles</Table.Cell>
                  <Table.Cell>
                    {formatRoles(user.roles).map((label) => (
                      <Label
                        key={label.key}
                        {...label}
                        style={{
                          marginBottom: '3px',
                          marginLeft: 0,
                          marginRight: '5px',
                        }}
                      />
                    ))}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Created At</Table.Cell>
                  <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Updated At</Table.Cell>
                  <Table.Cell>{formatDateTime(user.updatedAt)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
