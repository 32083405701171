import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Message, Confirm, Label } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';

import Filters from 'modals/Filters';
import EditSystem from 'modals/EditSystem';
import CreateUnlinkedSystem from 'modals/CreateUnlinkedSystem';
import { Icon } from 'semantic';
import { systemTypes, determineConnectivityStatus } from 'utils/systems';

@screen
export default class SetupSystems extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/systems/unlinked/search',
      body: {
        ...params,
        category: params.category?.id,
      },
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: systems,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Setup Systems" />
              <Layout horizontal center spread>
                <h1>Setup Systems</h1>
                <Layout.Group>
                  <CreateUnlinkedSystem
                    trigger={
                      <Button
                        basic
                        primary
                        content="New Test System"
                        icon="flask"
                      />
                    }
                    onSave={reload}
                  />
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="name" label="Name" />
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'vehicle',
                          value: 'vehicle',
                        },
                        {
                          text: 'vessel',
                          value: 'vessel',
                        },
                        {
                          text: 'stationary',
                          value: 'stationary',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="tags"
                      label="Tags"
                    />
                  </Filters>
                </Layout.Group>
              </Layout>
              <p>
                Systems shown here are newly connected and not yet linked to any
                organizations and customers.
              </p>
              {systems.length === 0 ? (
                <Message>No unlinked systems detected yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={getSorted('name')}
                        onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={getSorted('serialNumber')}
                        onClick={() => setSort('serialNumber')}>
                        Serial Number
                      </Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the system was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {systems.map((system) => {
                      const status = determineConnectivityStatus(system);
                      return (
                        <Table.Row key={system.id}>
                          <Table.Cell>
                            <Link to={`/systems/${system.id}`}>
                              <Icon
                                name={systemTypes[system.type || 'other'].icon}
                                style={{ marginRight: '8px' }}
                              />
                              {system.name || system.serialNumber}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            <code>{system.serialNumber}</code>
                          </Table.Cell>
                          <Table.Cell>
                            <Label {...status} />
                            <HelpTip
                              title="System Connectivity Status"
                              text={status.title}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {formatDateTime(system.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <EditSystem
                              system={system}
                              trigger={<Button basic icon="edit" />}
                              onSave={reload}
                            />
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${system.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/systems/${system.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
