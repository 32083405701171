import { REMOTE_EDGE_WEB_URL } from 'utils/env';
import { getToken } from 'utils/api';
import { formatRelativeTime } from 'utils/date';

export const systemTypes = {
  vessel: {
    name: 'Vessel',
    icon: 'ship',
  },
  vehicle: {
    name: 'Vehicle',
    icon: 'truck',
  },
  stationary: {
    name: 'Stationary',
    icon: 'building',
  },
  other: {
    name: 'Other',
    icon: 'cube',
  },
};

export function getRemoteMonitoringUrl({
  systemId,
  organizationId,
  deviceId = undefined,
}) {
  let url = `${REMOTE_EDGE_WEB_URL}/?apiMode=remote&destination=cloud-api&token=${getToken()}`;
  url += '&systemId=';
  url += typeof systemId === 'object' ? systemId.id : systemId;
  url += '&organizationId=';
  url +=
    typeof organizationId === 'object' ? organizationId.id : organizationId;
  if (deviceId) {
    url += '&deviceId=';
    url += deviceId && typeof deviceId === 'object' ? deviceId.id : deviceId;
  }
  return url;
}

export function systemIsOnline(system) {
  if (!system.lastMonitoringEventReceivedAt) {
    return false;
  }
  const timeoutMs = 15 * 60 * 1000;
  if (
    Date.parse(system.lastMonitoringEventReceivedAt) >
    Date.now() - timeoutMs
  ) {
    return true;
  }
  return false;
}

export function determineConnectivityStatus(system) {
  const isOnline = systemIsOnline(system);
  const label = {};
  if (isOnline) {
    label.color = 'olive';
    label.content = 'Online';
    label.title = `Last monitoring event was received ${formatRelativeTime(
      system.lastMonitoringEventReceivedAt
    )}.`;
  } else {
    label.content = 'Offline';
    label.title =
      'No monitoring events have been received in the past 15 minutes';
  }
  return label;
}

export function getSystemCoordinates(system) {
  if (!system) return null;
  if (
    !system.disableRealtimeLocation &&
    system.lastGeoLocation &&
    system.lastGeoLocation.coordinates &&
    system.lastGeoLocation.coordinates.length
  ) {
    return {
      latitude: system.lastGeoLocation.coordinates[1],
      longitude: system.lastGeoLocation.coordinates[0],
    };
  }
  if (
    system.address &&
    system.address.geometry &&
    system.address.geometry.coordinates &&
    system.address.geometry.coordinates.length
  ) {
    return {
      latitude: system.address.geometry.coordinates[1],
      longitude: system.address.geometry.coordinates[0],
    };
  }
  return null;
}
