const {
  API_URL,
  APP_NAME,
  SENTRY_DSN,
  ENV_NAME,
  GOOGLE_API_KEY,
  REMOTE_EDGE_WEB_URL,
  TECTONIC_URL,
} = window.__ENV__ || {};

export {
  API_URL,
  APP_NAME,
  SENTRY_DSN,
  ENV_NAME,
  GOOGLE_API_KEY,
  REMOTE_EDGE_WEB_URL,
  TECTONIC_URL,
};
