import React from 'react';

export const alertSeverities = {
  warning: {
    name: 'Warning',
    icon: 'exclamation-circle',
    color: 'yellow',
  },
  error: {
    name: 'Error',
    icon: 'exclamation-triangle',
    color: 'orange',
  },
};

export const alertStatuses = {
  new: {
    name: 'New',
    icon: 'envelope',
  },
  resolved: {
    name: 'Resolved',
    icon: 'check',
  },
};

export function formatAlertDescription(alert) {
  let valueStr = alert.deviceMonitoringValue;
  try {
    const value = JSON.parse(alert.deviceMonitoringValue);
    if (value.length) {
      valueStr = value.join(', ');
    }
  } catch (error) {
    console.log(
      `Warning error parsing alert.deviceMonitoringValue: ${error.message}`
    );
  }
  return (
    <React.Fragment>
      Device {alert.deviceMonitoringField} indicated <strong>{valueStr}</strong>
    </React.Fragment>
  );
}
