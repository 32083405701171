import React from 'react';
import { withSession } from 'stores';

function getDocumentQuery() {
  return new URLSearchParams(document.location.search);
}

@withSession
export default class Logout extends React.Component {
  async componentDidMount() {
    await this.context.setToken(null);
    const query = getDocumentQuery();
    if (query.get('message')) {
      document.location = '/?message=' + query.get('message');
    } else {
      document.location = '/';
    }
  }

  render() {
    return <div />;
  }
}
