import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Message, Button } from 'semantic';
import { request } from 'utils/api';
import { SearchProvider } from 'components';
import InspectObject from 'modals/InspectObject';
import { formatRelativeTime } from 'utils/date';
import { omit } from 'lodash-es';

export default class DevicesList extends React.Component {
  onDataNeeded = async (params) => {
    const { system } = this.props;
    return await request({
      method: 'POST',
      path: '/1/devices/search',
      body: {
        ...params,
        system: system.id,
        sort: {
          field: 'name',
          order: 'asc',
        },
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <SearchProvider onDataNeeded={this.onDataNeeded}>
          {({ items: devices, getSorted, setSort }) => {
            return (
              <React.Fragment>
                {devices.length === 0 ? (
                  <Message>No devices found yet</Message>
                ) : (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          sorted={getSorted('name')}
                          onClick={() => setSort('name')}>
                          Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={getSorted('serialNumber')}
                          onClick={() => setSort('serialNumber')}>
                          Serial Number
                        </Table.HeaderCell>
                        <Table.HeaderCell>Last Event</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          Actions
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {devices.map((device) => {
                        return (
                          <Table.Row key={device.id}>
                            <Table.Cell>
                              <Link
                                to={`/systems/${device.system.id}/devices/${device.id}`}>
                                {device.name}
                              </Link>
                              <p>
                                <small>{device.modelName}</small>
                              </p>
                            </Table.Cell>
                            <Table.Cell>
                              <code>{device.serialNumber}</code>
                            </Table.Cell>
                            <Table.Cell>
                              {formatRelativeTime(
                                device.lastMonitoringEventReceivedAt
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <InspectObject
                                object={omit(device, [
                                  'system',
                                  'organization',
                                ])}
                                trigger={
                                  <Button
                                    style={{ margin: 0 }}
                                    icon="file-code"
                                    basic
                                    content="JSON"
                                  />
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </React.Fragment>
            );
          }}
        </SearchProvider>
      </React.Fragment>
    );
  }
}
