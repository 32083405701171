import React from 'react';
import { Modal, Form, Loader, Message } from 'semantic';
import { request } from 'utils/api';
import { modal } from 'helpers';
import { Menu } from 'semantic-ui-react';
import { Layout } from 'components';

@modal
export default class OrganizationSelector extends React.Component {
  state = {
    organizations: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchOrganizations();
  }

  fetchOrganizations() {
    request({
      method: 'GET',
      path: '/1/organizations/mine',
    })
      .then(({ data }) => {
        this.setState({ loading: false, organizations: data });
      })
      .catch((error) => {
        this.setState({ loading: false, error });
      });
  }

  setOrganization(item) {
    localStorage.setItem('organizationId', item.id);
    document.location.reload();
  }
  render() {
    const { loading, error, organizations } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>Select Organization</Modal.Header>
        <Modal.Content>
          {loading && <Loader active />}
          {error && <Message error content={error.message} />}
          <Layout style={{ height: '300px' }}>
            {false && (
              <Form.Input
                id="id"
                icon="search"
                placeholder="Search for organization by name"
                fluid
                style={{ marginBottom: '20px' }}
              />
            )}
            {organizations && (
              <div style={{ height: 'auto', overflowY: 'auto' }}>
                <Menu secondary vertical fluid>
                  {organizations.map((organization) => {
                    return (
                      <Menu.Item
                        key={organization.id}
                        as="a"
                        to="#"
                        onClick={() => this.setOrganization(organization)}>
                        {organization.name}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </div>
            )}
          </Layout>
        </Modal.Content>
      </React.Fragment>
    );
  }
}
