import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Loader } from 'semantic';

import AccountDetails from './AccountDetails';

import { withSession } from 'stores';

@withSession
export default class Settings extends React.Component {
  render() {
    const { user } = this.context;
    if (!user) {
      return <Loader active>Loading</Loader>;
    }

    return (
      <Switch>
        <Route
          exact
          path="/settings"
          render={(props) => <AccountDetails {...props} {...this.context} />}
        />
      </Switch>
    );
  }
}
