import React from 'react';
import { Header, Table, Image, Label, Icon } from 'semantic';
import { screen } from 'helpers';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';
import { formatRelativeTime } from '../../../utils/date';
import DeviceMonitorSummary from 'components/DeviceMonitorSummary';

@screen
export default class DeviceOverview extends React.Component {
  render() {
    const { device } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Header as="h3">Current State</Header>
        <Table definition>
          <Table.Body>
            <DeviceMonitorSummary device={device} />
          </Table.Body>
        </Table>
        <Header as="h3">Device Details</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={6}>Device Class</Table.Cell>
              <Table.Cell>{device.className || 'Unknown'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Device Name</Table.Cell>
              <Table.Cell>{device.name || 'Unknown'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Model Name</Table.Cell>
              <Table.Cell>{device.modelName || 'Unknown'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Serial Number</Table.Cell>
              <Table.Cell>
                <code>{device.serialNumber || 'None'}</code>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Monitoring Event</Table.Cell>
              <Table.Cell>
                {formatRelativeTime(device.lastMonitoringEventReceivedAt)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(device.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(device.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
