import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Map from './Map';
import Detail from './Detail';
import Energy from './Energy';
import Generator from './Generator';

export default class Systems extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/systems" component={List} exact />
        <Route path="/systems/map" component={Map} exact />
        <Route path="/systems/energy" component={Energy} exact />
        <Route path="/systems/generator" component={Generator} exact />
        <Route path="/systems/:id" component={Detail} />
      </Switch>
    );
  }
}
