import React from 'react';
import { Icon, Divider, Button, Card, Image, Confirm } from 'semantic';
import { formatRelativeTime } from 'utils/date';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { SearchProvider } from 'components';
import { ExternalLink } from 'components/Link';
import Menu from './Menu';
import EditNote from 'modals/EditNote';
import ReactMarkdown from 'react-markdown';
import { urlForUpload } from '../../../utils/uploads';

@screen
export default class Notes extends React.Component {
  onDataNeeded = async (params) => {
    const { system } = this.props;
    return await request({
      method: 'POST',
      path: '/1/notes/search',
      body: {
        ...params,
        system: system.id,
      },
    });
  };

  render() {
    const { system } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <SearchProvider onDataNeeded={this.onDataNeeded}>
          {({ items: notes, reload }) => {
            return (
              <React.Fragment>
                <EditNote
                  system={system}
                  trigger={<Button primary content="New Note" icon="pen" />}
                  onSave={reload}
                />
                {notes.map((note) => {
                  return (
                    <Card fluid key={note.id}>
                      <Card.Content>
                        <Confirm
                          negative
                          confirmButton="Delete"
                          header={`Are you sure you want to delete this note?`}
                          content="All data will be permanently deleted"
                          trigger={
                            <a style={{ float: 'right' }}>
                              <Icon name="trash" />
                            </a>
                          }
                          onConfirm={async () => {
                            await request({
                              method: 'DELETE',
                              path: `/1/notes/${note.id}`,
                            });
                            reload();
                          }}
                        />
                        <Card.Header>{note.creatorUser.name}</Card.Header>
                        <Card.Meta>
                          {formatRelativeTime(note.createdAt)}
                        </Card.Meta>
                        <Card.Description>
                          {(note.images.length > 0 ||
                            note.documents.length > 0) && (
                            <>
                              {note.images.length > 0 && (
                                <>
                                  {note.images.map((image) => {
                                    return (
                                      <p key={image.id}>
                                        <ExternalLink
                                          href={urlForUpload(image)}>
                                          <Image
                                            size="medium"
                                            src={urlForUpload(image)}
                                          />
                                        </ExternalLink>
                                      </p>
                                    );
                                  })}
                                  <Divider hidden />
                                </>
                              )}

                              {note.documents.length > 0 && (
                                <ul
                                  style={{
                                    listStyleType: 'none',
                                    paddingLeft: 0,
                                  }}>
                                  {note.documents.map((document) => {
                                    return (
                                      <li key={document.id}>
                                        <ExternalLink
                                          target="_blank"
                                          href={urlForUpload(document)}>
                                          <Button
                                            basic
                                            icon="file-pdf"
                                            content={document.filename}
                                            src={urlForUpload(document)}
                                          />
                                        </ExternalLink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                              <Divider hidden />
                            </>
                          )}
                          <ReactMarkdown>{note.body}</ReactMarkdown>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  );
                })}
              </React.Fragment>
            );
          }}
        </SearchProvider>
      </React.Fragment>
    );
  }
}
