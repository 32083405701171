import React from 'react';
import { startCase } from 'lodash';
import {
  Card,
  Transition,
  Divider,
  Icon,
  Message,
  Button,
  Header,
  Loader,
} from 'semantic';
import { formatRelativeTime } from 'utils/date';
import { request } from 'utils/api';
import { HelpTip, SearchProvider, Layout } from 'components';

import InspectObject from 'modals/InspectObject';
import EditSystemCommand from 'modals/EditSystemCommand';
import Filters from 'modals/Filters';
import { systemControlActions } from 'utils/commands';
import CodeBlock from 'components/CodeBlock';
import { urlForUpload } from 'utils/uploads';

function colorForCommand(command) {
  if (command.error) {
    return 'red';
  }
  if (command.status === 'pending' && !command.result) {
    return 'yellow';
  }
  if (command.status === 'completed' && command.result) {
    return 'olive';
  }
  return 'grey';
}

function renderResult({ action, result }) {
  if (!result) return '';
  if (action === 'downloadLogs' && result.upload && result.upload.filename) {
    return (
      <Button
        basic
        content={result.upload.filename}
        icon="download"
        as="a"
        href={urlForUpload(result.upload)}
        target="_blank"
      />
    );
  }
  return <CodeBlock value={JSON.stringify(result, null, 2)} language="json" />;
}

export default class Commands extends React.Component {
  onDataNeeded = async (params) => {
    const { system } = this.props;
    return await request({
      method: 'POST',
      path: `/1/systems/${system.id}/commands/search`,
      body: {
        ...params,
      },
    });
  };

  render() {
    const { system } = this.props;
    return (
      <SearchProvider live onDataNeeded={this.onDataNeeded}>
        {({
          items: commands,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Layout horizontal center spread>
                <Header as="h3">System Commands</Header>
                <EditSystemCommand
                  system={system}
                  trigger={
                    <Button
                      primary
                      content="Execute System Command"
                      icon="terminal"
                    />
                  }
                  onSave={reload}
                />
              </Layout>
              <Divider hidden />
              {commands.length === 0 ? (
                <Message>No commands executed yet</Message>
              ) : (
                <Transition.Group
                  animation="fade down"
                  as={Card.Group}
                  duration={600}
                  divided>
                  {commands
                    .filter((item) => !!systemControlActions[item.action])
                    .map((item) => {
                      const hasResult =
                        (item.result && Object.keys(item.result).length) ||
                        item.error;
                      const actionInfo = systemControlActions[item.action];
                      return (
                        <Card key={item.id} fluid color={colorForCommand(item)}>
                          <Card.Content>
                            <Card.Header>
                              <Icon
                                size="large"
                                style={{
                                  float: 'right',
                                  display: 'inline-block',
                                  position: 'relative',
                                  color: 'black',
                                  top: 0,
                                  right: 0,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  lineHeight: '1.5',
                                  cursor: 'default',
                                }}
                                name={actionInfo.icon}
                              />
                              {actionInfo.name}
                            </Card.Header>
                            <Card.Meta>
                              {formatRelativeTime(item.createdAt)}
                            </Card.Meta>
                            <Card.Description>
                              {item.params && (
                                <code>
                                  {JSON.stringify(item.params, null, 2)}
                                </code>
                              )}
                              {hasResult && (
                                <>
                                  {renderResult(item)}
                                  {item.error && (
                                    <p>
                                      <Message
                                        error
                                        header={item.error.type}
                                        content={item.error.message}
                                      />
                                    </p>
                                  )}
                                </>
                              )}
                            </Card.Description>
                          </Card.Content>
                          <Card.Content extra>
                            <InspectObject
                              object={item}
                              trigger={
                                <Icon
                                  name="file-code"
                                  color="gray"
                                  style={{
                                    float: 'right',
                                    cursor: 'pointer',
                                  }}
                                />
                              }
                            />
                            {startCase(item.status)}
                            {item.status === 'pending' && <Loader active />}
                          </Card.Content>
                        </Card>
                      );
                    })}
                </Transition.Group>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
