import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';

import DateField from 'components/form-fields/Date';

export default class EditOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
      loading: false,
      organization: props.organization || {},
    };
  }

  componentDidUpdate(lastProps) {
    const { organization } = this.props;
    if (organization && organization !== lastProps.organization) {
      this.setState({
        organization,
      });
    }
  }

  isUpdate() {
    return !!this.props.organization;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      organization: {
        ...this.state.organization,
        [name]: value,
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { organization } = this.state;
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/organizations/${organization.id}`,
          body: {
            ...organization,
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/organizations',
          body: {
            ...organization,
          },
        });
        this.setState({
          organization: {},
        });
      }
      this.setState({
        open: false,
        loading: false,
      });
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { trigger } = this.props;
    const { organization, open, loading, error } = this.state;
    return (
      <Modal
        closeIcon
        open={open}
        trigger={trigger}
        closeOnDimmerClick={false}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}>
        <Modal.Header>
          {this.isUpdate() ? `Edit "${organization.name}"` : 'New Organization'}
        </Modal.Header>
        <Modal.Content scrolling>
          <AutoFocus>
            <Form
              noValidate
              id="edit-organization"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={organization.name || ''}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-organization"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
