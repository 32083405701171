import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import { modal } from 'helpers';

import UploadsField from 'components/form-fields/Uploads';
import ReferenceField from 'components/form-fields/Reference';

@modal
export default class EditNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      note: props.note || {},
    };
  }

  isUpdate() {
    return !!this.props.note;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      note: {
        ...this.state.note,
        [name]: value,
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { note } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/notes/${note.id}`,
          body: {
            ...note,
            ...(this.props.system && {
              system: this.props.system.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/notes',
          body: {
            ...note,
            ...(this.props.system && {
              system: this.props.system.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
      }
      this.props.onSave();
      this.props.close();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { note, loading, error } = this.state;
    return (
      <>
        <Modal.Header>
          {this.isUpdate() ? `Edit Note` : 'New Note'}
        </Modal.Header>
        <Modal.Content scrolling>
          <AutoFocus>
            <Form
              noValidate
              id="edit-note"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              {!this.props.system && (
                <ReferenceField
                  required
                  name="system"
                  label="System"
                  value={note.system}
                  onChange={this.setField}
                  resource="Systems"
                />
              )}
              <UploadsField
                name="images"
                label="Images (Optional)"
                value={note.images || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <UploadsField
                name="documents"
                type="pdf"
                label="Documents (Optional)"
                value={note.documents || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <Form.Field>
                <a
                  style={{ float: 'right' }}
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Formatting
                </a>
                <label>Body</label>
                <Form.TextArea
                  name="body"
                  value={note.body || ''}
                  onChange={this.setField}
                />
              </Form.Field>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-note"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </>
    );
  }
}
