import React from 'react';
import { Header, Table, Button, Divider } from 'semantic';
import { screen } from 'helpers';
import Menu from '../Menu';

import { formatDateTime, formatRelativeTime } from 'utils/date';
import { formatOption } from 'utils/formatting';
import { systemTypes } from 'utils/systems';
import InspectObject from 'modals/InspectObject';

import Devices from './Devices';
import Commands from './Commands';

@screen
export default class SystemOverview extends React.Component {
  render() {
    const { system } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Header as="h3">Details</Header>
        <Table definition collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Serial Number</Table.Cell>
              <Table.Cell>{system.serialNumber || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Monitoring Event Received At</Table.Cell>
              <Table.Cell>
                {formatRelativeTime(system.lastMonitoringEventReceivedAt)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <div>
          <InspectObject
            object={system}
            trigger={<Button icon="file-code" basic content="System JSON" />}
          />
          <InspectObject
            object={system.state}
            trigger={
              <Button icon="file-code" basic content="System State JSON" />
            }
          />
        </div>
        <Divider hidden />

        {system.firmwareVersions && (
          <>
            <Header as="h3">Firmware Version</Header>
            <Table definition collapsing>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>WhisperCare</Table.Cell>
                  <Table.Cell>{system.firmwareVersions.whispercare}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>WhisperCare UI</Table.Cell>
                  <Table.Cell>
                    {system.firmwareVersions.whispercareUI}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Bootloader</Table.Cell>
                  <Table.Cell>{system.firmwareVersions.bootloader}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Host System</Table.Cell>
                  <Table.Cell>{system.firmwareVersions.hostSystem}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Host UI</Table.Cell>
                  <Table.Cell>{system.firmwareVersions.hostUI}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
          </>
        )}

        <Header as="h3">Devices</Header>
        <Devices system={system} />
        <Divider hidden />
        <Commands system={system} />
      </React.Fragment>
    );
  }
}
