import React from 'react';
import { Label } from 'semantic-ui-react';

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export function truncate(text, limit = 100) {
  if (text.length > limit - 3) {
    return text.slice(0, limit - 3) + '...';
  }
  return text;
}

export function formatNumber(value, locale) {
  const formatter = new Intl.NumberFormat(locale);
  return formatter.format(value);
}

export function formatOption(types, key) {
  const status = types[key];
  const props = {
    content: status.name,
    icon: status.icon,
  };
  if (status.color) {
    props.color = status.color;
  }
  return <Label {...props} />;
}

export function formatAddress(address) {
  const components = [];
  if (address.line1) {
    components.push(address.line1);
  }
  if (address.city) {
    components.push(address.city);
  }
  if (address.stateOrProvince) {
    components.push(address.stateOrProvince);
  }
  if (address.countryCode) {
    components.push(address.countryCode);
  }
  return components.join(', ');
}

function valueForPrecision(value, precision, defaultPrecision) {
  if (value === undefined || value === null) return '-';
  if (typeof value === 'string') return value;
  if (precision === 0) return Math.round(value).toString();
  if (precision) {
    return value.toFixed(precision);
  }
  return value.toFixed(defaultPrecision);
}

export function convertUnits({ value, unit, precision }) {
  switch (unit) {
    case 'W':
      if (value > 1000) {
        return {
          value: valueForPrecision(value / 1000, precision, 1),
          unit: 'kW',
        };
      }
      return {
        value: valueForPrecision(value, precision, 0),
        unit,
      };
    case 'V':
      return {
        value: valueForPrecision(value, precision, 1),
        unit,
      };
    case 'VAC':
      return {
        value: valueForPrecision(value, precision, 1),
        unit,
      };
    case 'VDC':
      return {
        value: valueForPrecision(value, precision, 1),
        unit,
      };
    case 'Vac':
      return {
        value: valueForPrecision(value, precision, 1),
        unit,
      };
    case 'Vdc':
      return {
        value: valueForPrecision(value, precision, 1),
        unit,
      };
    case 'kW':
      if (value < 1.0) {
        return {
          value: valueForPrecision(value * 1000, precision, 0),
          unit: 'W',
        };
      }
      return {
        value: valueForPrecision(value, precision, 1),
        unit: 'kW',
      };
    case 'VA':
      if (value > 1000) {
        return {
          value: valueForPrecision(value / 1000, precision, 1),
          unit: 'kVA',
        };
      }
      return {
        value: valueForPrecision(value, precision, 0),
        unit: 'VA',
      };
    case 'A':
      return {
        value: valueForPrecision(value, precision, 1),
        unit: 'A',
      };
    case '%':
      return {
        value: valueForPrecision(value, precision, 0),
        unit: '%',
      };
    case 'minutes':
      return {
        value: valueForPrecision(value, precision, 0),
        unit: 'minutes',
        unitElement: <span>&nbsp;minutes</span>,
      };
    case 'celcius':
      return {
        value: valueForPrecision(value, precision, 1),
        unit: 'celcius',
        unitElement: '°C',
      };
    case 'Wh':
      if (value > 1000) {
        if (value > 1000000) {
          return {
            value: valueForPrecision(value / 1000000, precision, 3),
            unit: 'MWh',
          };
        }
        return {
          value: valueForPrecision(value / 1000, precision, 3),
          unit: 'kWh',
        };
      }
      return {
        value: valueForPrecision(value, precision, 0),
        unit: 'Wh',
      };
  }
  return {
    value: valueForPrecision(value, precision, 0),
    unit,
  };
}

export function formatByUnit({ value, unit, type, precision }, options = {}) {
  if (value === undefined) {
    return null;
  }
  if (value === null) {
    return null;
  }
  if (type === 'array') {
    if (!value.length) {
      return '-';
    }
    if (value && value[0] && value.join && typeof value[0] === 'string') {
      return value.join(', ');
    }
    if (typeof value === 'string') {
      return value;
    }
    return JSON.stringify(value);
  }
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  const {
    value: newValue,
    unit: newUnit,
    unitElement,
  } = convertUnits({
    value,
    unit,
    precision,
    ...options,
  });
  return (
    <>
      {newValue}
      <span style={{ fontWeight: 'bold' }}>{unitElement || newUnit}</span>
    </>
  );
}
