import React from 'react';
import { Header, Table, Loader, Message, Label } from 'semantic';
import { screen } from 'helpers';
import Menu from '../Menu';
import { request } from 'utils/api';

import { formatDateTime } from 'utils/date';
import { formatOption } from 'utils/formatting';
import { systemTypes } from 'utils/systems';
import SystemDevicesMonitoringSummary from './SystemDevicesMonitoringSummary';
import RemoteSystemBanner from 'components/RemoteSystemBanner';

@screen
export default class SystemOverview extends React.Component {
  state = { loading: true };
  componentDidMount() {
    this.fetchDevices();
  }
  async fetchDevices() {
    const { system } = this.props;
    try {
      const { data: devices } = await request({
        method: 'POST',
        path: '/1/devices/search',
        body: {
          system: system.id,
          sort: {
            field: 'name',
            order: 'asc',
          },
        },
      });
      this.setState({ devices, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  render() {
    const { system } = this.props;
    const { loading, error, devices = [] } = this.state;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {loading && <Loader />}
        {error && <Message error content={error.message} />}
        <Header as="h3">System Summary</Header>
        <SystemDevicesMonitoringSummary system={system} devices={devices} />
        <Header as="h3">System Details</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={6}>Type</Table.Cell>
              <Table.Cell>
                {formatOption(systemTypes, system.type || 'other')}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Tags</Table.Cell>
              <Table.Cell>{system.tags.join(', ') || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Serial Number</Table.Cell>
              <Table.Cell>{system.serialNumber || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(system.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(system.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
