import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import { get, set } from 'lodash';

import { createDropdownOptions } from 'utils/form';
import { systemCommandTypes, systemControlActions } from 'utils/commands';

export default class EditCommand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
      loading: false,
      command: props.command || {
        type: 'control',
      },
    };
  }

  componentDidUpdate(lastProps) {
    const { command } = this.props;
    if (command && command !== lastProps.command) {
      this.setState({
        command,
      });
    }
  }

  isUpdate() {
    return !!this.props.command;
  }

  setField = (evt, { name, value }) => {
    const command = {
      ...this.state.command,
    };
    set(command, name, value);
    this.setState({
      command,
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { system } = this.props;
      const { command } = this.state;
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/systems/${system.id}/commands/${command.id}`,
          body: {
            ...command,
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
      } else {
        await request({
          method: 'POST',
          path: `/1/systems/${system.id}/commands`,
          body: {
            ...command,
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
            ...(this.props.organization && {
              organization: this.props.organization.id,
            }),
          },
        });
        this.setState({
          command: {},
        });
      }
      this.setState({
        open: false,
        loading: false,
      });
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { trigger } = this.props;
    const { command, open, loading, error } = this.state;
    return (
      <Modal
        closeIcon
        open={open}
        trigger={trigger}
        closeOnDimmerClick={false}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}>
        <Modal.Header>
          {this.isUpdate()
            ? `Edit ${command.name || 'Command'}`
            : 'Execute Command'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-command"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                selection
                name="type"
                label="Type"
                value={get(command, 'type') || ''}
                options={createDropdownOptions(systemCommandTypes)}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                name="action"
                label="Action"
                value={get(command, 'action') || ''}
                options={createDropdownOptions(systemControlActions)}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-command"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Execute'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
