import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Message, Label } from 'semantic';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { Breadcrumbs, SearchProvider, Layout } from 'components';
import { formatByUnit } from 'utils/formatting';
import { formatRelativeTime } from 'utils/date';

import Filters from 'modals/Filters';
import { Icon } from 'semantic';
import { systemTypes } from 'utils/systems';
@screen
export default class MySystems extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/systems/search',
      body: {
        ...params,
        category: params.category?.id,
      },
    });
  };

  render() {
    return (
      <SearchProvider live onDataNeeded={this.onDataNeeded}>
        {({
          items: systems,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="My Systems Generator Overview" />
              <Layout horizontal center spread>
                <h1>My Systems Generator Overview</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="name" label="Name" />
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'vehicle',
                          value: 'vehicle',
                        },
                        {
                          text: 'vessel',
                          value: 'vessel',
                        },
                        {
                          text: 'stationary',
                          value: 'stationary',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="tags"
                      label="Tags"
                    />
                  </Filters>
                </Layout.Group>
              </Layout>
              <Divider hidden />
              {systems.length === 0 ? (
                <Message>No systems configured yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={getSorted('name')}
                        onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        Starter Battery
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                      <Table.HeaderCell width={3}>RPM</Table.HeaderCell>
                      <Table.HeaderCell width={3}>Autostart</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {systems
                      .filter((system) => {
                        return system?.state?.generatorStatus?.length > 0;
                      })
                      .map((system) => {
                        const generatorStatus =
                          system.state?.generatorStatus || [];
                        return (
                          <Table.Row key={system.id}>
                            <Table.Cell>
                              <Link to={`/systems/${system.id}`}>
                                <Icon
                                  name={
                                    systemTypes[system.type || 'other'].icon
                                  }
                                  style={{ marginRight: '8px' }}
                                />
                                {system.name || system.serialNumber}
                              </Link>
                              <p style={{ color: '#999999' }}>
                                <small>
                                  Last update:&nbsp;
                                  {formatRelativeTime(
                                    system.lastMonitoringEventReceivedAt
                                  )}
                                </small>
                              </p>
                            </Table.Cell>
                            <Table.Cell>
                              {generatorStatus.map((generator) => {
                                return (
                                  <Label key={generator.deviceId}>
                                    <Icon name="car-battery" />
                                    {formatByUnit({
                                      value: generator.starterBatteryVoltage,
                                      unit: 'V',
                                    })}
                                  </Label>
                                );
                              })}
                            </Table.Cell>
                            <Table.Cell>
                              {generatorStatus.map((generator) => {
                                return (
                                  <Label
                                    key={generator.deviceId}
                                    color={
                                      generator.generatorStatus === 'Running'
                                        ? 'olive'
                                        : undefined
                                    }>
                                    {generator.generatorStatus}
                                  </Label>
                                );
                              })}
                            </Table.Cell>
                            <Table.Cell>
                              {generatorStatus.map((generator) => {
                                return (
                                  <Label key={generator.deviceId}>
                                    {Math.round(generator.generatorRpm)}
                                  </Label>
                                );
                              })}
                            </Table.Cell>
                            <Table.Cell>
                              {generatorStatus
                                .map((generator) => {
                                  return generator.generatorStatus === 'Running'
                                    ? `Started by ${generator.startedBy}`
                                    : '-';
                                })
                                .join(', ')}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
