import React from 'react';
import bem from 'helpers/bem';
import { Icon, Popup } from 'semantic';
import { Layout } from 'components';

function iconForBatteryStateOfCharge(soc) {
  if (soc > 85) {
    return 'battery-full';
  }
  if (soc > 60) {
    return 'battery-three-quarters';
  }
  if (soc > 35) {
    return 'battery-half';
  }
  if (soc > 20) {
    return 'battery-quarter';
  }
  return 'battery-empty';
}

@bem
export default class SystemStatusIcons extends React.Component {
  render() {
    const {
      system,
      showText = true,
      vertical = false,
      marginRight = '20px',
    } = this.props;
    const { state } = system;
    if (!state || !state.functionalStatus) return <div />;
    const { functionalStatus } = state;
    const functionalStatusKeys = Object.keys(functionalStatus);
    const baseIconStyle = {
      color: 'rgb(86, 92, 102)',
      float: 'left',
      marginRight: '10px',
    };
    const activeStyle = { ...baseIconStyle, color: '#247870' };
    const inActiveStyle = {
      opacity: '0.3',
      ...baseIconStyle,
    };
    const itemStyle = vertical
      ? { display: 'block', width: '100%', clear: 'both' }
      : { display: 'inline-block', marginRight };
    const itemTextStyle = { display: 'block', float: 'left' };
    return (
      <div className={this.getBlockClass()} style={this.props.style || {}}>
        {functionalStatusKeys.includes('gridInputPresent') && (
          <Popup
            content={
              functionalStatus.gridInputPresent
                ? 'Grid input is detected'
                : 'Grid input was NOT detected'
            }
            trigger={
              <span style={itemStyle}>
                <Icon
                  size="large"
                  name="ac-in"
                  custom
                  fitted
                  style={
                    functionalStatus.gridInputPresent
                      ? activeStyle
                      : inActiveStyle
                  }
                />
                {showText && (
                  <span
                    style={{
                      ...itemTextStyle,
                      marginBottom: vertical ? '5px' : '',
                    }}>
                    {functionalStatus.gridInputPresent ? 'Connected' : '---'}
                  </span>
                )}
              </span>
            }
          />
        )}
        {functionalStatusKeys.includes('generatorRunning') && (
          <Popup
            content={
              functionalStatus.generatorRunning
                ? 'Generator is running'
                : 'Generator is NOT running'
            }
            trigger={
              <span style={itemStyle}>
                <Icon
                  size="large"
                  name="generator"
                  custom
                  fitted
                  style={
                    functionalStatus.generatorRunning
                      ? activeStyle
                      : inActiveStyle
                  }
                />
                {showText && (
                  <span
                    style={{
                      ...itemTextStyle,
                      marginBottom: vertical ? '5px' : '',
                    }}>
                    {functionalStatus.generatorRunning ? 'Running' : 'Standby'}
                  </span>
                )}
              </span>
            }
          />
        )}
        {functionalStatusKeys.includes('solarInputPresent') && (
          <Popup
            content={
              functionalStatus.solarInputPresent
                ? 'Solar input is detected'
                : 'Solar input is NOT detected'
            }
            trigger={
              <span style={itemStyle}>
                <Icon
                  size="large"
                  name="solar-charger"
                  custom
                  fitted
                  style={
                    functionalStatus.solarInputPresent
                      ? activeStyle
                      : inActiveStyle
                  }
                />
                {showText && (
                  <span
                    style={{
                      ...itemTextStyle,
                      marginBottom: vertical ? '5px' : '',
                    }}>
                    {functionalStatus.solarInputPresent ? 'Charging' : '---'}
                  </span>
                )}
              </span>
            }
          />
        )}
        {functionalStatusKeys.includes('batteryStateOfCharge') && (
          <Popup
            content={`Lowest battery is at ${functionalStatus.batteryStateOfCharge}%`}
            trigger={
              <span style={itemStyle}>
                <Icon
                  size="large"
                  style={activeStyle}
                  name={iconForBatteryStateOfCharge(
                    functionalStatus.batteryStateOfCharge
                  )}
                  title={`${functionalStatus.batteryStateOfCharge}%`}
                />
                {showText && (
                  <span style={itemTextStyle}>
                    {Math.round(functionalStatus.batteryStateOfCharge)}%
                  </span>
                )}
              </span>
            }
          />
        )}
      </div>
    );
  }
}
