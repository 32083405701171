import React from 'react';
import { screen } from 'helpers';
import Alerts from 'components/lists/Alerts';
import { Breadcrumbs } from 'components';

@screen
export default class MyAlerts extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumbs active="My Alerts" />
        <Alerts title="My Alerts" baseParams={{}} />
      </React.Fragment>
    );
  }
}
