import React from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Divider,
  Button,
  Message,
  Confirm,
  Label,
  Dropdown,
} from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { ExternalLink } from 'components/Link';
import { getRemoteMonitoringUrl } from 'utils/systems';

import Filters from 'modals/Filters';
import { Icon } from 'semantic';
import { systemTypes, determineConnectivityStatus } from 'utils/systems';
import SystemStatusIcons from 'components/SystemStatusIcons';
import { screenIsMobilePortrait } from 'utils/mobile';

@screen
export default class MySystems extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/systems/search',
      body: {
        ...params,
        category: params.category?.id,
      },
    });
  };

  render() {
    const isMobile = screenIsMobilePortrait();
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: systems,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="My Systems" />
              <Layout horizontal center spread>
                <h1>My Systems</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="name" label="Name" />
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'vehicle',
                          value: 'vehicle',
                        },
                        {
                          text: 'vessel',
                          value: 'vessel',
                        },
                        {
                          text: 'stationary',
                          value: 'stationary',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="tags"
                      label="Tags"
                    />
                  </Filters>
                </Layout.Group>
              </Layout>
              <Divider hidden />
              {systems.length === 0 ? (
                <Message>No systems configured yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={getSorted('name')}
                        onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      {!isMobile && (
                        <React.Fragment>
                          <Table.HeaderCell width={6.5}>
                            Status
                            <HelpTip
                              title="System Status"
                              text="This is the functional status determined by various monitoring values on core devices."
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width={1.5}>
                            Connectivity
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>Alerts</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center" width={1}>
                            Actions
                          </Table.HeaderCell>
                        </React.Fragment>
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {systems.map((system) => {
                      const status = determineConnectivityStatus(system);
                      return (
                        <Table.Row key={system.id}>
                          <Table.Cell>
                            <Link to={`/systems/${system.id}`}>
                              <Icon
                                name={systemTypes[system.type || 'other'].icon}
                                style={{ marginRight: '8px' }}
                              />
                              {system.name || system.serialNumber}
                            </Link>
                          </Table.Cell>

                          {!screenIsMobilePortrait() && (
                            <React.Fragment>
                              <Table.Cell textAlign="center">
                                <SystemStatusIcons
                                  system={system}
                                  showText={false}
                                  marginRight="10px"
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Label {...status} />
                                <HelpTip
                                  title="System Connectivity Status"
                                  text={status.title}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                {system.newAlerts.length > 0 ? (
                                  <Label
                                    as={Link}
                                    to={`/systems/${system.id}/alerts`}
                                    icon="bell"
                                    color="orange"
                                    content={`${system.newAlerts.length} active`}
                                  />
                                ) : (
                                  '-'
                                )}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Button
                                  primary
                                  as={ExternalLink}
                                  href={getRemoteMonitoringUrl({
                                    systemId: system.id,
                                    organizationId: system.organization,
                                  })}
                                  icon="desktop"
                                  title="Remote Monitor"
                                />
                              </Table.Cell>
                            </React.Fragment>
                          )}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
