import { startCase, get } from 'lodash';

export const SUMMARY_MONITORING_FIELDS = {
  'battery-monitor': [
    'operationalState',
    'battery.stateOfCharge',
    'battery.voltage',
    'battery.current',
  ],
  'battery-charger': [
    'operationalState',
    'acInput.voltage',
    'acInput.current',
    'dcSummary.voltage',
    'dcSummary.current',
  ],
  'solar-charger': [
    'operationalState',
    'solarInput.power',
    'dc.voltage',
    'dc.current',
    'solarInput.dailyProduction',
  ],
  generator: [
    'operationalState',
    'engine.starterBatteryVoltage',
    'engine.rpm',
    'acOutput.powerTotal',
    'historic.nextMaintenanceHours',
  ],
  inverter: [
    'operationalState',
    'dcSummary.voltage',
    'dcSummary.current',
    'acOutput.voltage',
    'acOutput.current',
  ],
  'combi-charger-inverter': [
    'operationalState',
    'acInput.voltage',
    'acInput.current',
    'acOutput.voltage',
    'acOutput.current',
    'dcSummary.voltage',
    'dcSummary.current',
  ],
  'voltage-monitor': [
    'operationalState',
    'battery1.voltage',
    'battery2.voltage',
    'battery3.voltage',
    'battery4.voltage',
  ],
  'power-converter': [
    'operationalState',
    'acOutput.power',
    'acOutput.voltage',
    'acOutput.current',
    'acInput.voltage',
    'acInput.current',
  ],
};

export const precisionForUnit = {
  V: 2,
  A: 2,
  VA: 1,
  '%': 1,
  minutes: 0,
  celcius: 2,
};

export function getGroupedMetrics(monitoring = {}, path = []) {
  let metrics = [];
  const keys = Object.keys(monitoring);
  let items = [];
  keys.forEach((key) => {
    if (monitoring[key].displayIsHidden) return;
    items.push({
      ...monitoring[key],
      key,
    });
  });
  items = items.sort((itemA, itemB) => {
    return (itemB.displayPriority || 1) - (itemA.displayPriority || 1);
  });
  items.forEach((item) => {
    const { key } = item;
    if (item.type === 'object' && item.properties) {
      metrics.push({
        type: 'header',
        title: startCase(key),
        path: key,
      });
      metrics = metrics.concat(getGroupedMetrics(item.properties, [key]));
      return;
    }
    metrics.push({
      type: 'metric',
      ...item,
      icon: getIconForItem({
        ...item,
        path: path.concat([key]).join('.'),
      }),
      path: path.concat([key]).join('.'),
      empty: getEmptyMessage(item),
    });
  });
  return metrics;
}

export function getEmptyMessage(item) {
  const key = item?.key;
  switch (key) {
    case 'alarms':
    case 'warnings':
      return 'None';
    case 'operationalState':
      return 'Normal';
    default:
      return '-';
  }
}

export function getIconForItem(item) {
  const { key, unit, path } = item;
  let name;
  let custom = false;

  switch (path) {
    case 'temperature.battery':
      name = 'thermometer-three-quarters';
      break;
  }

  if (!name) {
    switch (key) {
      case 'alarms':
        name = 'exclamation-circle';
        break;
      case 'operationalState':
        name = 'check-circle';
        break;
      case 'warnings':
        name = 'exclamation-triangle';
        break;
      case 'current':
        name = 'wave-square';
        break;
      case 'voltage':
        name = 'bolt';
        break;
      case 'generatorRequest':
        name = 'plug';
        break;
      case 'dipswitches':
        name = 'microchip';
        break;
      case 'stateOfCharge':
        name = 'battery-three-quarters';
        break;
      case 'capacityDrawn':
        name = 'arrow-alt-circle-down';
        break;
      case 'capacityRemaining':
        name = 'arrow-alt-circle-up';
        break;
      case 'starterBatteryVoltage':
        name = 'car-battery';
        break;
      case 'timeRemaining':
        name = 'clock';
        break;
      case 'batteryChargingState':
        name = 'charging-station';
        break;
      case 'battery':
        name = 'car-battery';
        break;
      case 'digitalOutput1':
      case 'digitalOutput2':
        name = 'microchip';
        break;
      case 'engineStartedBy':
        name = 'toggle-on';
        break;
    }
  }

  if (!name) {
    switch (unit) {
      case 'celcius':
        name = 'thermometer-three-quarters';
        break;
      case 'Hz':
        name = 'wave-square';
        break;
      case 'V':
        name = 'bolt';
        break;
      case 'A':
        name = 'bolt';
        break;
      case 'Ma':
        name = 'bolt';
        break;
      case 'VA':
        name = 'bolt';
        break;
      case 'W':
        name = 'bolt';
        break;
      case 'rpm':
        name = 'redo';
        break;
    }
  }

  if (!name && path) {
    switch (path.split('.')[0]) {
      case 'digitalIO':
        name = 'microchip';
        break;
    }
  }

  if (!name && path) {
    if (path.match(/digital/i)) {
      name = 'microchip';
    }
    if (path.match(/load/)) {
      name = 'tachometer-alt';
    }
  }

  if (!name) {
    name = 'circle';
  }

  return {
    name,
    custom,
  };
}

export function pickMonitoringValues(device, deviceState, fields) {
  return fields.map((path) => {
    const definitionPath = path.split('.').join('.properties.');
    const definition = get(device.monitoring, definitionPath);
    const value = get(deviceState?.event || {}, path);
    const icon = getIconForItem({
      key: path.split('.').slice(-1)[0],
      path,
      ...definition,
    });
    return {
      path,
      definition,
      value,
      icon,
      emptyMessage: getEmptyMessage(definition),
    };
  });
}
