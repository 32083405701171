import React from 'react';
import { withSession } from 'stores';
import { userHasAccess } from 'utils/permissions';

@withSession
export default class Protected extends React.Component {
  render() {
    const { user, organization } = this.context;
    const { endpoint, permission = 'read', scope = 'global' } = this.props;
    const hasAccess =
      user &&
      userHasAccess(user, {
        endpoint,
        permission,
        scope,
        scopeRef: organization && organization.id,
      });
    return <React.Fragment>{hasAccess && this.props.children}</React.Fragment>;
  }
}
