import React from 'react';
import { screen } from 'helpers';
import Alerts from 'components/lists/Alerts';
import Menu from './Menu';

@screen
export default class SystemAlerts extends React.Component {
  render() {
    const { system } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Alerts
          title="Alerts"
          baseParams={{
            system: system.id,
          }}
        />
      </React.Fragment>
    );
  }
}
