import React from 'react';
import { screen } from 'helpers';
import Menu from './Menu';
import { Header, Segment, Button, Grid, Image, Icon, Divider } from 'semantic';
import remoteControlImage from 'assets/images/remote-control-screenshot-2.png';
import { ExternalLink } from 'components/Link';
import { getRemoteMonitoringUrl } from 'utils/systems';

@screen
export default class SystemMonitoring extends React.Component {
  render() {
    const { system } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        <div>
          <div style={{ float: 'left', margin: '1em' }}>
            <Image src={remoteControlImage} size="large" />
          </div>
          <div style={{ padding: '1em' }}>
            <p>Remote monitoring and control is available for this system.</p>
            <div>
              <Button
                as={ExternalLink}
                href={getRemoteMonitoringUrl({
                  systemId: system.id,
                  organizationId: system.organization,
                })}
                primary
                content="Launch Remote Control"
                size="huge"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
