import React from 'react';
import { Header, Table, Image, Label } from 'semantic';
import { screen } from 'helpers';
import Menu from './Menu';
import Alerts from 'components/lists/Alerts';

@screen
export default class DeviceAlerts extends React.Component {
  render() {
    const { device } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Alerts
          title="Alerts"
          baseParams={{
            system: device.system.id,
            device: device.id,
          }}
        />
      </React.Fragment>
    );
  }
}
