import React from 'react';
import { request } from 'utils/api';
import { Segment, Grid, Divider, Button, Message } from 'semantic';
import { withSession } from 'stores';
import { screen } from 'helpers';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';

import LoginForm from './Form';
import { Link } from 'react-router-dom';

function getDocumentQuery() {
  return new URLSearchParams(document.location.search);
}

@screen
@withSession
export default class Login extends React.Component {
  static layout = 'none';

  state = {
    error: null,
    loading: false,
    message: getDocumentQuery().get('message'),
  };

  onSubmit = async (body) => {
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'POST',
        path: '/1/auth/login',
        body,
      });
      this.context.setToken(data.token);
      await this.context.load();
      this.props.history.push('/');
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  async demoLogin() {
    try {
      this.setState({
        error: null,
        demoLoading: true,
      });
      const { data } = await request({
        method: 'POST',
        path: '/1/auth/login/demo',
        body: {},
      });
      this.context.setToken(data.token);
      await this.context.load();
      this.props.history.push('/');
    } catch (error) {
      this.setState({
        error,
        demoLoading: false,
      });
    }
  }

  render() {
    const { error, loading, demoLoading, message } = this.state;
    return (
      <PageCenter>
        <LogoTitle title="Login" />
        <Segment.Group>
          <Segment padded>
            {message && <Message info content={message} />}
            <LoginForm
              onSubmit={this.onSubmit}
              error={error}
              loading={loading}
            />
            <Divider hidden />
            <p>Don't have an account? Try our demo dashboard:</p>
            <div>
              <Button
                fluid
                basic
                size="large"
                content="Demo Login"
                loading={demoLoading}
                onClick={() => {
                  this.demoLogin();
                }}
              />
            </div>
          </Segment>
          <Segment secondary>
            <Grid>
              <Grid.Column floated="left" width={8}></Grid.Column>
              <Grid.Column floated="right" width={8} textAlign="right">
                <Link to="/forgot-password">Forgot Password</Link>
              </Grid.Column>
            </Grid>
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}
