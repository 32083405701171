import React from 'react';
import { Segment, Message, Form, Button, Divider } from 'semantic';
import { screen } from 'helpers';
import moment from 'moment-timezone';
import Menu from './Menu';
import { request } from 'utils/api';
import { escapeRegExp } from 'lodash';
import UploadsField from 'components/form-fields/Uploads';

@screen
export default class AccountDetails extends React.Component {
  state = {
    user: this.props.user,
  };

  setField = (evt, { name, value }) => {
    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  async save() {
    const { user } = this.state;
    try {
      this.setState({
        loading: true,
        error: null,
      });
      const { data } = await request({
        method: 'PATCH',
        path: `/1/users/me`,
        body: user,
      });
      this.setState({
        user: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { user, error, loading } = this.state;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        {error && <Message error content={error.message} />}
        <Form onSubmit={() => this.save()}>
          <Segment>
            <Form.Input
              type="text"
              name="email"
              label="E-mail"
              value={user.email || ''}
              onChange={this.setField}
            />

            <Form.Input
              type="text"
              name="name"
              label="Name"
              value={user.name || ''}
              onChange={this.setField}
            />

            <Form.Dropdown
              name="timeZone"
              selection
              search={(options, query) => {
                const re = new RegExp(escapeRegExp(query), 'i');
                return options.filter((opt) => re.test(opt.text));
              }}
              options={moment.tz.names().map((key) => {
                return {
                  key,
                  value: key,
                  text: key,
                };
              })}
              label="Time Zone"
              onChange={this.setField}
              value={user.timeZone}
            />
          </Segment>
          <Button
            primary
            content="Save"
            loading={loading}
            disabled={loading}
            style={{ margin: 0 }}
          />
        </Form>
      </React.Fragment>
    );
  }
}
