import { hot } from 'react-hot-loader/root';

import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import { AuthSwitch, Protected } from 'helpers/routes';

import Dashboard from 'screens/Dashboard';
import Docs from 'screens/Docs';
import Components from 'screens/Components';
import Invites from 'screens/Invites';
import NotFound from 'screens/NotFound';
import Settings from 'screens/Settings';
import Users from 'screens/Users';
import Team from 'screens/Team';

import AcceptInvite from 'screens/Auth/AcceptInvite';
import ForgotPassword from 'screens/Auth/ForgotPassword';
import ResetPassword from 'screens/Auth/ResetPassword';
import Login from 'screens/Auth/Login';
import Logout from 'screens/Auth/Logout';
import Signup from 'screens/Auth/Signup';
import Organizations from 'screens/Organizations';
import Systems from 'screens/Systems';
import Alerts from 'screens/Alerts';
import DeviceDetail from 'screens/Devices/Detail';
import UnlinkedSystems from 'screens/UnlinkedSystems';

const App = () => (
  <Switch>
    <Protected path="/systems/unlinked" allowed={UnlinkedSystems} />
    <Protected path="/systems/:systemId/devices/:id" allowed={DeviceDetail} />
    <Protected path="/systems/:id?" allowed={Systems} />
    <Protected path="/alerts/:id?" allowed={Alerts} />
    <Protected path="/organizations/:id?" allowed={Organizations} />
    <AuthSwitch path="/" loggedIn={Dashboard} loggedOut={Login} exact />
    <Protected path="/settings" allowed={Settings} exact />
    <Protected path="/team/invites" allowed={Invites} exact />
    <Protected path="/team" allowed={Team} />
    <Protected path="/users/:id?" allowed={Users} />
    <Protected path="/docs/:id?" allowed={Docs} />
    <Protected path="/components" allowed={Components} exact />
    <Route path="/logout" component={Logout} exact />
    <AuthSwitch
      path="/login"
      loggedOut={Login}
      loggedIn={() => <Redirect to="/" />}
      exact
    />
    <AuthSwitch
      path="/signup"
      loggedOut={Signup}
      loggedIn={() => <Redirect to="/" />}
      exact
    />
    <Route path="/accept-invite" component={AcceptInvite} exact />
    <Route path="/forgot-password" component={ForgotPassword} exact />
    <Route path="/reset-password" component={ResetPassword} exact />
    <Route component={NotFound} />
  </Switch>
);

export default hot(App);
