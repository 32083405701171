import React from 'react';
import { Header, Table, Image, Label, Icon } from 'semantic';
import { request } from 'utils/api';

import { formatByUnit } from 'utils/formatting';
import { SUMMARY_MONITORING_FIELDS, pickMonitoringValues } from 'utils/devices';

export default class DeviceMonitorSummary extends React.Component {
  state = {
    deviceState:
      this.props.deviceState || this.props.device?.lastMonitoringEvent,
    error: null,
  };
  componentDidMount() {
    if (!this.props.deviceState) {
      this.interval = setInterval(() => {
        this.update();
      }, 4000);
      this.update();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.deviceState &&
      JSON.stringify(prevProps.deviceState) !==
        JSON.stringify(this.props.deviceState)
    ) {
      this.setState({ deviceState: this.props.deviceState });
    }
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  async update() {
    try {
      const { data: deviceState } = await request({
        method: 'GET',
        path: `/1/devices/${this.props.device.id}/monitoring-events`,
      });
      this.setState({ deviceState });
    } catch (error) {
      this.setState({ error });
    }
  }
  render() {
    const { device } = this.props;
    const { deviceState } = this.state;
    return pickMonitoringValues(
      device,
      deviceState,
      SUMMARY_MONITORING_FIELDS[device.className] || []
    )
      .filter(({ path, definition }) => {
        if (!definition) {
          console.warn(
            `Could not find definition for ${path} on device ${device.id} (${device.name}, ${device.className})`
          );
          return false;
        }
        return true;
      })
      .map(({ path, definition, value, icon, emptyMessage }) => {
        return (
          <Table.Row key={path}>
            <Table.Cell title={definition.description?.en} width={6}>
              <Icon size="small" {...icon} style={{ marginRight: '10px' }} />
              {definition?.name?.en}
            </Table.Cell>
            <Table.Cell title={path}>
              {formatByUnit({
                value,
                unit: definition.unit,
                type: definition.type,
              }) || emptyMessage}
            </Table.Cell>
          </Table.Row>
        );
      });
  }
}
