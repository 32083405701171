import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';

function defaultSystem() {
  return {
    serialNumber: Math.random().toString(16).substr(2, 20),
  };
}

export default class CreateUnlinkedSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: null,
      loading: false,
      system: props.system || defaultSystem(),
    };
  }

  componentDidUpdate(lastProps) {
    const { system } = this.props;
    if (system && system !== lastProps.system) {
      this.setState({
        system,
      });
    }
  }

  setField = (evt, { name, value }) => {
    this.setState({
      system: {
        ...this.state.system,
        [name]: value,
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { system } = this.state;
      await request({
        method: 'POST',
        path: '/1/systems/unlinked',
        body: {
          ...system,
        },
      });
      this.setState({
        system: defaultSystem(),
      });
      this.setState({
        open: false,
        loading: false,
      });
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { trigger } = this.props;
    const { system, open, loading, error } = this.state;
    return (
      <Modal
        closeIcon
        open={open}
        trigger={trigger}
        closeOnDimmerClick={false}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}>
        <Modal.Header>Create Test System</Modal.Header>
        <Modal.Content scrolling>
          <AutoFocus>
            <p>This creates a new system for testing purposes.</p>
            <Form
              noValidate
              id="edit-system"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                type="text"
                name="serialNumber"
                label="Serial Number"
                value={system.serialNumber || ''}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-system"
            loading={loading}
            disabled={loading}
            content="Save"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
