import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Message, Button, Label, Confirm } from 'semantic';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { SearchProvider } from 'components';
import { ExternalLink } from 'components/Link';
import { getRemoteMonitoringUrl } from 'utils/systems';
import { formatRelativeTime } from 'utils/date';

import Menu from './Menu';
import { screenIsMobilePortrait } from 'utils/mobile';

@screen
export default class DevicesList extends React.Component {
  onDataNeeded = async (params) => {
    const { system } = this.props;
    return await request({
      method: 'POST',
      path: '/1/devices/search',
      body: {
        ...params,
        system: system.id,
        sort: {
          field: 'name',
          order: 'asc',
        },
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <SearchProvider onDataNeeded={this.onDataNeeded}>
          {({ items: devices, getSorted, setSort, reload }) => {
            return (
              <React.Fragment>
                <Divider hidden />
                {devices.length === 0 ? (
                  <Message>No devices found yet</Message>
                ) : (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          sorted={getSorted('name')}
                          onClick={() => setSort('name')}>
                          Name
                        </Table.HeaderCell>
                        {!screenIsMobilePortrait() && (
                          <React.Fragment>
                            <Table.HeaderCell
                              sorted={getSorted('serialNumber')}
                              onClick={() => setSort('serialNumber')}>
                              Serial Number
                            </Table.HeaderCell>
                            <Table.HeaderCell>Active Alerts</Table.HeaderCell>
                            <Table.HeaderCell>Last Update</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                              Actions
                            </Table.HeaderCell>
                          </React.Fragment>
                        )}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {devices.map((device) => {
                        return (
                          <Table.Row key={device.id}>
                            <Table.Cell>
                              <Link
                                to={`/systems/${device.system.id}/devices/${device.id}`}>
                                {device.name || device.modelName}
                              </Link>
                              <p>
                                <small>{device.modelName}</small>
                              </p>
                            </Table.Cell>
                            {!screenIsMobilePortrait() && (
                              <React.Fragment>
                                <Table.Cell>
                                  <code>{device.serialNumber}</code>
                                </Table.Cell>
                                <Table.Cell>
                                  {device.newAlerts.length > 0 ? (
                                    <Label
                                      as={Link}
                                      to={`/systems/${device.system.id}/devices/${device.id}/alerts`}
                                      icon="bell"
                                      color="orange"
                                      content={`${device.newAlerts.length} active alerts`}
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  {formatRelativeTime(
                                    device.lastMonitoringEventReceivedAt
                                  )}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Button
                                    primary
                                    as={ExternalLink}
                                    href={getRemoteMonitoringUrl({
                                      systemId: device.system.id,
                                      organizationId:
                                        device.system.organization,
                                      deviceId: device.id,
                                    })}
                                    icon="desktop"
                                    title="Remote Monitor"
                                  />
                                </Table.Cell>
                              </React.Fragment>
                            )}
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </React.Fragment>
            );
          }}
        </SearchProvider>
      </React.Fragment>
    );
  }
}
