import React from 'react';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { Table, Divider, Icon, Message, Button } from 'semantic';
import { formatRelativeTime } from 'utils/date';
import { request } from 'utils/api';
import { HelpTip, SearchProvider, Layout } from 'components';

import Filters from 'modals/Filters';
import {
  alertSeverities,
  alertStatuses,
  formatAlertDescription,
} from 'utils/alerts';
import { createDropdownOptions } from 'utils/form';

export default class Alerts extends React.Component {
  onDataNeeded = async (params) => {
    const { baseParams } = this.props;
    return await request({
      method: 'POST',
      path: '/1/alerts/search',
      body: {
        ...params,
        ...baseParams,
      },
    });
  };

  async resolve(item) {
    await request({
      method: 'PATCH',
      path: '/1/alerts/' + item.id,
      body: {
        status: 'resolved',
      },
    });
  }

  async unresolve(item) {
    await request({
      method: 'PATCH',
      path: '/1/alerts/' + item.id,
      body: {
        status: 'new',
      },
    });
  }

  render() {
    const { title } = this.props;
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: alerts,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Layout horizontal center spread>
                <h1>{title}</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Dropdown
                      search
                      name="severity"
                      label="Severity"
                      options={createDropdownOptions(alertSeverities)}
                    />
                    <Filters.Dropdown
                      search
                      name="status"
                      label="Status"
                      options={createDropdownOptions(alertStatuses)}
                    />
                  </Filters>
                </Layout.Group>
              </Layout>
              <Divider hidden />
              {alerts.length === 0 ? (
                <Message>No alerts triggered yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        Device / System
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={getSorted('severity')}
                        onClick={() => setSort('severity')}
                        textAlign="center">
                        Severity
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={getSorted('status')}
                        onClick={() => setSort('status')}
                        textAlign="center">
                        Status
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the alert was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={2}>
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {alerts.map((alert) => {
                      const severityObject = alertSeverities[alert.severity];
                      return (
                        <Table.Row
                          key={alert.id}
                          style={{
                            opacity: alert.status === 'resolved' ? 0.6 : 1,
                          }}>
                          <Table.Cell>
                            {formatAlertDescription(alert)}
                          </Table.Cell>
                          <Table.Cell>
                            <Link
                              to={`/systems/${alert.system.id}/devices/${alert.device.id}`}>
                              {alert.device.modelName ||
                                alert.device.serialNumber}
                            </Link>
                            <p>
                              <small>
                                (
                                <Link to={`/systems/${alert.system.id}`}>
                                  {alert.system.name ||
                                    alert.system.serialNumber}
                                </Link>
                                )
                              </small>
                            </p>
                          </Table.Cell>
                          <Table.Cell
                            textAlign="center"
                            error={alert.severity === 'error'}
                            warning={alert.severity === 'warning'}>
                            <Icon name={severityObject.icon} />{' '}
                            {severityObject.name}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {startCase(alert.status)}
                          </Table.Cell>
                          <Table.Cell>
                            {formatRelativeTime(alert.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {alert.status === 'new' && (
                              <Button
                                basic
                                icon="check"
                                title="Resolve this issue"
                                onClick={async () => {
                                  await this.resolve(alert);
                                  reload();
                                }}
                              />
                            )}
                            {alert.status === 'resolved' && (
                              <Button
                                basic
                                icon="undo"
                                title="Unresolve this issue"
                                onClick={async () => {
                                  await this.unresolve(alert);
                                  reload();
                                }}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
