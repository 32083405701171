import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Message, Label } from 'semantic';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { Breadcrumbs, SearchProvider, Layout } from 'components';
import { formatByUnit } from 'utils/formatting';
import { formatRelativeTime } from 'utils/date';

import Filters from 'modals/Filters';
import { Icon } from 'semantic';
import { systemTypes } from 'utils/systems';

@screen
export default class MySystems extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/systems/search',
      body: {
        ...params,
        category: params.category?.id,
      },
    });
  };

  render() {
    return (
      <SearchProvider live onDataNeeded={this.onDataNeeded}>
        {({
          items: systems,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="My Systems Energy Overview" />
              <Layout horizontal center spread>
                <h1>My Systems Energy Overview</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="name" label="Name" />
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'vehicle',
                          value: 'vehicle',
                        },
                        {
                          text: 'vessel',
                          value: 'vessel',
                        },
                        {
                          text: 'stationary',
                          value: 'stationary',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="tags"
                      label="Tags"
                    />
                  </Filters>
                </Layout.Group>
              </Layout>
              <Divider hidden />
              {systems.length === 0 ? (
                <Message>No systems configured yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={getSorted('name')}
                        onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell width={5}>Input</Table.HeaderCell>
                      <Table.HeaderCell width={5}>Storage</Table.HeaderCell>
                      <Table.HeaderCell width={3}>Consumers</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {systems.map((system) => {
                      const state = system.state?.energyStatus || {};
                      const gridInput = state.gridInput || [];
                      const gridInputPresent = gridInput.filter(
                        (i) => i.gridPresent === true
                      );
                      const solar = state.solar || [];
                      const solarPresent = solar.filter(
                        (i) => i.solarPresent === true
                      );
                      const battery = state.battery || [];
                      const consumerOutput = state.consumerOutput || [];
                      return (
                        <Table.Row key={system.id}>
                          <Table.Cell>
                            <Link to={`/systems/${system.id}`}>
                              <Icon
                                name={systemTypes[system.type || 'other'].icon}
                                style={{ marginRight: '8px' }}
                              />
                              {system.name || system.serialNumber}
                            </Link>
                            <p style={{ color: '#999999' }}>
                              <small>
                                Last update:&nbsp;
                                {formatRelativeTime(
                                  system.lastMonitoringEventReceivedAt
                                )}
                              </small>
                            </p>
                          </Table.Cell>
                          <Table.Cell>
                            {gridInput.slice(0, 2).map((input) => {
                              return (
                                <Label key={input.gridId} color="olive">
                                  <Icon name="Grid" custom />
                                  {formatByUnit({
                                    value: input.current,
                                    unit: 'A',
                                  })}
                                </Label>
                              );
                            })}
                            {solarPresent.slice(0, 2).map((input) => {
                              return (
                                <Link
                                  to={`/systems/${system.id}/devices/${input.deviceId}`}
                                  key={input.deviceId}
                                  style={{ margin: '0' }}>
                                  <Label
                                    key={input.gridId}
                                    color="yellow"
                                    style={{
                                      margin: '0.14285714em 0.14285714em',
                                    }}>
                                    <Icon name="Sun" custom />
                                    {formatByUnit({
                                      value: input.power,
                                      unit: 'W',
                                    })}
                                  </Label>
                                </Link>
                              );
                            })}
                          </Table.Cell>
                          <Table.Cell>
                            {battery.slice(0, 4).map((input) => {
                              return (
                                <Link
                                  to={`/systems/${system.id}/devices/${input.deviceId}`}
                                  key={input.deviceId}
                                  style={{ margin: '0' }}>
                                  <Label
                                    key={input.deviceId}
                                    color="blue"
                                    style={{
                                      margin: '0.14285714em 0.14285714em',
                                    }}>
                                    <Icon name="Battery_big" custom />
                                    {formatByUnit({
                                      value: input.stateOfCharge,
                                      unit: '%',
                                    })}
                                  </Label>
                                </Link>
                              );
                            })}
                          </Table.Cell>
                          <Table.Cell>
                            {consumerOutput.slice(0, 2).map((input) => {
                              return (
                                <Label key={input.deviceId} color="orange">
                                  <Icon name="Connector" custom />
                                  {formatByUnit({
                                    value: input.current,
                                    unit: 'A',
                                  })}
                                </Label>
                              );
                            })}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
