import React from 'react';
import { Icon, Table, Message, Label } from 'semantic';
import { screen } from 'helpers';
import Menu from './Menu';
import { get } from 'lodash';

import { formatDateTime } from 'utils/date';
import { formatByUnit } from 'utils/formatting';
import { formatRelativeTime } from 'utils/date';
import { getGroupedMetrics } from 'utils/devices';
import { request } from 'utils/api';

import DeviceMonitorSummary from 'components/DeviceMonitorSummary';

@screen
export default class DeviceMonitor extends React.Component {
  state = {
    deviceState: this.props.device?.lastMonitoringEvent,
    error: null,
  };
  componentDidMount() {
    this.update();
    this.interval = setInterval(() => {
      this.update();
    }, 4000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async update() {
    try {
      const { data: deviceState } = await request({
        method: 'GET',
        path: `/1/devices/${this.props.device.id}/monitoring-events`,
      });
      this.setState({ deviceState });
    } catch (error) {
      this.setState({ error });
    }
  }
  render() {
    const { deviceState, error } = this.state;
    const { device } = this.props;
    const monitoringSections = getGroupedMetrics(device.monitoring);
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {error && <Message error content={error.message} />}
        <Table definition>
          <Table.Body>
            <Table.Row key="summary">
              <Table.Cell colSpan={2}>
                <Label ribbon>Summary</Label>
              </Table.Cell>
            </Table.Row>
            <DeviceMonitorSummary device={device} deviceState={deviceState} />
            {monitoringSections[0] && monitoringSections[0].type !== 'header' && (
              <Table.Row key="general">
                <Table.Cell colSpan={2}>
                  <Label ribbon>General</Label>
                </Table.Cell>
              </Table.Row>
            )}
            {monitoringSections.map((item) => {
              return item.type === 'header' ? (
                <Table.Row key={item.path}>
                  <Table.Cell colSpan={2}>
                    <Label ribbon>{item.title}</Label>
                  </Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row key={item.path}>
                  <Table.Cell title={item.description?.en}>
                    <Icon
                      size="small"
                      {...item.icon}
                      style={{ marginRight: '10px' }}
                    />
                    {item.name?.en || '-'}
                  </Table.Cell>
                  <Table.Cell title={item.path}>
                    {formatByUnit({
                      value: get(deviceState?.event, item.path),
                      unit: item.unit,
                      type: item.type,
                    }) || item.empty}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
