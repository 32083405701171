import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider, Button, Message, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import { screen } from 'helpers';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';

import Filters from 'modals/Filters';
import EditSystem from 'modals/EditSystem';
import { Icon } from 'semantic';
import { systemTypes } from 'utils/systems';

import GoogleMap from './GoogleMap';

@screen
export default class MySystems extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/systems/search',
      body: {
        ...params,
        category: params.category?.id,
      },
    });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: systems,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs
                link={<Link to="/systems">Systems</Link>}
                active="Map"></Breadcrumbs>
              <Layout horizontal center spread>
                <h1>My Systems</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="name" label="Name" />
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'vehicle',
                          value: 'vehicle',
                        },
                        {
                          text: 'vessel',
                          value: 'vessel',
                        },
                        {
                          text: 'stationary',
                          value: 'stationary',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="tags"
                      label="Tags"
                    />
                  </Filters>
                </Layout.Group>
              </Layout>
              <Divider hidden />
              <GoogleMap items={systems} />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
