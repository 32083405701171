import React from 'react';
import { withSession } from 'stores';
import Organization from 'modals/Organization';
import { Icon } from 'semantic';
import { truncate } from 'utils/formatting';

@withSession
export default class OrganizationSwitcher extends React.Component {
  render() {
    const { user, organization } = this.context;
    return (
      <Organization
        trigger={
          <div>
            <Icon name="building" />
            {organization && truncate(organization.name, 20)}
            <Icon name="caret-down" className="right" />
          </div>
        }
        size="tiny"
      />
    );
  }
}
