import React from 'react';
import { Divider, Table, Loader, Message, Label } from 'semantic';
import { screen } from 'helpers';
import Menu from './Menu';
import { getSystemCoordinates } from 'utils/systems';
import GoogleMap from './../Map/GoogleMap';

@screen
export default class SystemMap extends React.Component {
  render() {
    const { system } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        {getSystemCoordinates(system) ? (
          <GoogleMap items={[system]} zoom={16} />
        ) : (
          <Message content="No location is known for this system" />
        )}
      </React.Fragment>
    );
  }
}
