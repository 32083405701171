import React from 'react';
import { Header, Table, Loader, Message, Label, Icon } from 'semantic';
import { get } from 'lodash';
import { request } from 'utils/api';

import { formatDateTime } from 'utils/date';
import { formatByUnit } from 'utils/formatting';
import { systemTypes } from 'utils/systems';
import { getDeviceClasses, mergeDeviceState } from 'utils/deviceClass';
import {
  SUMMARY_MONITORING_FIELDS,
  getIconForItem,
  getEmptyMessage,
} from 'utils/devices';

import DeviceMonitorSummary from 'components/DeviceMonitorSummary';

export default class SystemDevicesMonitoringSummary extends React.Component {
  state = { loading: true };
  componentDidMount() {
    this.fetch();
    this.updateInterval = setInterval(() => this.update(), 4000);
  }
  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }
  async fetch() {
    const { system } = this.props;
    try {
      const { data: events } = await request({
        method: 'POST',
        path: '/1/devices/monitoring-events/search',
        body: {
          system: system.id,
        },
      });
      this.setState({ events, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  async update() {
    const { system } = this.props;
    try {
      const { data: events } = await request({
        method: 'POST',
        path: '/1/devices/monitoring-events/search',
        body: {
          system: system.id,
        },
      });
      this.setState({ events, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  render() {
    const { system, devices } = this.props;
    const { loading, error, events = [] } = this.state;
    if (loading) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        <Table definition>
          <Table.Body>
            {devices
              .filter(
                (device) =>
                  !!device.lastMonitoringEvent &&
                  !!device.lastMonitoringEventReceivedAt
              )
              .map((device) => {
                if (!device)
                  return <React.Fragment key={`${device.deviceId}-unknown`} />;
                return (
                  <React.Fragment key={device.deviceId}>
                    <Table.Row key="general">
                      <Table.Cell colSpan={2}>
                        <Label ribbon>{device.name}</Label>
                      </Table.Cell>
                    </Table.Row>
                    <DeviceMonitorSummary device={device} />
                  </React.Fragment>
                );
              })}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
