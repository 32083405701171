import React from 'react';
import { Header, Segment, Button, Grid, Image, Icon, Divider } from 'semantic';
import { ExternalLink } from 'components/Link';
import { getRemoteMonitoringUrl } from 'utils/systems';
import remoteControlImage from 'assets/images/remote-control-screenshot-1.png';

export default class RemoteSystemBanner extends React.Component {
  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            float: 'right',
            right: '10px',
            top: '10px',
            zIndex: 1000,
          }}>
          <Icon name="close" />
        </div>
        <Segment placeholder>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Image
                  src={remoteControlImage}
                  size="medium"
                  style={{ float: 'right' }}
                />
              </Grid.Column>
              <Grid.Column>
                <p>
                  Remote monitoring and control is available for this system
                </p>
                <Button
                  as={ExternalLink}
                  href={getRemoteMonitoringUrl({
                    systemId: system.id,
                    organizationId: system.organization,
                  })}
                  primary
                  style={{ float: 'left' }}
                  content="Launch Remote Control"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

/*
export default class RemoteSystemBanner extends React.Component {
  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            float: 'right',
            right: '10px',
            top: '10px',
            zIndex: 1000,
          }}>
          <Icon name="close" />
        </div>
        <Segment placeholder>
          <Header icon>
            <Icon name="desktop" />
            Remote monitoring and control is available for this system
          </Header>
          <Button primary>Launch Remote Control</Button>
        </Segment>
      </div>
    );
  }
}
*/
